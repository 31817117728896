import React, { useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter } from 'react-router-dom';
import { SendBirdInstance } from 'sendbird';
import { clearSession } from 'msal';
import './App.scss';

import { registryApi } from 'services/registry';
import { selectIsPatient, tokenParsed } from 'store/auth';
import { useLazyGetAppConfigQuery } from 'services/configurator';
import { useAppDispatch, useAppSelector } from 'store/index';

import { AppRouter } from './routes';

import { disconnect, handleConnection, sdkSelector } from 'store/messaging/sdk';
import { loadState } from 'utils/localStorage';
import { configSelector, setTenant } from 'store/account';
import { setUid } from 'store/notifications';
import useActionsNotifications from 'hooks/useActionsNotifications';
import useCalendarNotifications from 'hooks/useCalendarNotifications';
import useChatNotifications from 'hooks/useChatNotifications';
import { useMsal } from '@azure/msal-react';
import { useSbCalls } from 'hooks/sbCalls';

export default function App() {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const sbCalls = useSbCalls();
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const messagingSdk = useAppSelector(sdkSelector);
  const notifications = loadState('notifications');
  const { handleSSE } = useActionsNotifications();
  const { handlePatientsAssignToClinicianForNotifications } = useChatNotifications();
  const { handleFetchCalendarEventsForNotifications } = useCalendarNotifications();
  const isPatient = useAppSelector(selectIsPatient);
  const [fetchUserAccount] = registryApi.endpoints.fetchUserAccount.useLazyQuery();
  const [fetchAppConfig] = useLazyGetAppConfigQuery();
  const config = useAppSelector(configSelector);

  const onCall = useMemo(() => {
    return sbCalls?.calls.find((call) => call.isOngoing && call.callState !== 'ended');
  }, [sbCalls]);

  const onIdle = () => {
    if (isLeader()) {
      if (onCall) {
        activate();
      } else {
        dispatch(disconnect(messagingSdk as SendBirdInstance) as any);
        sbCalls.deauth();
        dispatch(setTenant(undefined));
        clearSession();
        instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin + '/',
        });
      }
    }
  };

  //handle inactivity of user
  const { activate, isLeader } = useIdleTimer({
    timeout: config?.app?.idleTimerTimeout || 15 * 60 * 1000,
    onIdle: onIdle,
    eventsThrottle: 3000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (tokenParsedSelector?.extension_AllHealthID) {
      fetchUserAccount({ userId: tokenParsedSelector.extension_AllHealthID });
    }
  }, [dispatch, fetchUserAccount, tokenParsedSelector]);

  useEffect(() => {
    if (
      tokenParsedSelector?.extension_AllHealthID &&
      config?.app?.sendbird_app_id &&
      !messagingSdk &&
      !isPatient
    ) {
      if (config.tenant?.chat !== 'OFF') {
        // Handle Sendbird Connection
        dispatch(
          handleConnection({
            appId: config.app?.sendbird_app_id,
            userId: tokenParsedSelector.extension_AllHealthID,
          }) as any
        );
      }

      // Handle Sendbird Calls Connection
      sbCalls.init(config.app?.sendbird_app_id);
      sbCalls.auth({ userId: tokenParsedSelector.extension_AllHealthID });
    }
    // eslint-disable-next-line
  }, [dispatch, tokenParsedSelector, config, messagingSdk, isPatient]);

  useEffect(() => {
    handleSSE('ACTION');
    handleSSE('ACTION_BY_USER');
  }, [handleSSE]);

  useEffect(() => {
    fetchAppConfig();
    handleFetchCalendarEventsForNotifications();
    handlePatientsAssignToClinicianForNotifications();
  }, [
    fetchAppConfig,
    handleFetchCalendarEventsForNotifications,
    handlePatientsAssignToClinicianForNotifications,
  ]);

  useEffect(() => {
    tokenParsedSelector?.extension_AllHealthID &&
      dispatch(setUid(tokenParsedSelector?.extension_AllHealthID));
    if (!notifications?.uid || !tokenParsedSelector?.extension_AllHealthID) {
      return;
    }
    if (tokenParsedSelector?.extension_AllHealthID !== notifications?.uid) {
      localStorage.removeItem('notifications');
    }
  }, [dispatch, notifications?.uid, tokenParsedSelector?.extension_AllHealthID]);

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}
