import { createApi } from '@reduxjs/toolkit/query/react';

import { apiBaseQuery } from 'services/base';
import { RootState } from 'store/rootReducer';

export interface AppConfig {
  sendbird_app_id: string;
  idleTimerTimeout: number;
  tenant_config_path_url: string;
  azure_ad_b2c: {
    clientId: string;
    authority: string;
    knownAuthorities: string[];
  };
}

interface TenantConfig {
  chat: 'ON' | 'OFF';
  hosts: Record<string, string>;
  reportExternalIds: ReportExternalId[];
}

export interface ReportExternalId {
  displayName: string;
  externalId: string;
  useInReports: boolean;
}

export interface Config {
  app: AppConfig;
  tenant: TenantConfig;
}

export const configuratorApi: any = createApi({
  reducerPath: 'configuratorApi',
  baseQuery: apiBaseQuery('/configurator/api/v1'),
  tagTypes: ['AppConfig'],
  endpoints(builder) {
    return {
      getAppConfig: builder.query<Config, void>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const tenant = (queryApi.getState() as RootState).auth.tenant;
          const [configUrlResponse, tenantConfigUrlResponse] = await Promise.all([
            fetch('/configurator/api/v1/config?global=true', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                tenantId: tenant?.tenant_ID,
                tenantCode: tenant?.tenant,
              } as HeadersInit,
            }),
            fetch(`/configurator/api/v1/config?tenant_id=${tenant?.tenant_ID}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                tenantId: tenant?.tenant_ID,
                tenantCode: tenant?.tenant,
              } as HeadersInit,
            }),
          ]);

          if (configUrlResponse.status !== 200) {
            return {
              error: { status: configUrlResponse.status, data: configUrlResponse.statusText },
            };
          }

          if (tenantConfigUrlResponse.status !== 200) {
            return {
              error: {
                status: tenantConfigUrlResponse.status,
                data: tenantConfigUrlResponse.statusText,
              },
            };
          }

          const configs = await configUrlResponse.json();
          const tenantConfigs = await tenantConfigUrlResponse.json();

          try {
            const configUrl = configs?.[0];
            const tenantConfigUrl = tenantConfigs?.find((config: string) =>
              config.includes('web.json')
            );
            const [configResponse, tenantConfigResponse] = await Promise.all([
              fetch(`/configurator/api/v1/config/get?file_name=${configUrl}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  tenantId: tenant?.tenant_ID,
                  tenantCode: tenant?.tenant,
                } as HeadersInit,
              }),
              fetch(`/configurator/api/v1/config/get?file_name=${tenantConfigUrl}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  tenantId: tenant?.tenant_ID,
                  tenantCode: tenant?.tenant,
                } as HeadersInit,
              }),
            ]);

            if (configResponse.status !== 200) {
              return { error: { status: configResponse.status, data: configResponse.statusText } };
            }

            if (tenantConfigResponse.status !== 200) {
              return {
                error: {
                  status: tenantConfigResponse.status,
                  data: tenantConfigResponse.statusText,
                },
              };
            }

            const config: AppConfig = await configResponse.json();
            const tenantConfig: TenantConfig = await tenantConfigResponse.json();
            return { data: { app: config, tenant: tenantConfig } };
          } catch (error) {
            return { error: { status: undefined, data: error } };
          }
        },
        providesTags: ['AppConfig'],
      }),
    };
  },
});

export const { useLazyGetAppConfigQuery } = configuratorApi;
export const selectAppConfigQueryResult = () => configuratorApi.endpoints.getAppConfig.select();
