import { Configuration, LogLevel } from '@azure/msal-browser';

// MSAL configuration
export const getMsalConfig = (config: any): Configuration => ({
  auth: {
    clientId: config?.azure_ad_b2c?.clientId ?? '',
    authority: config?.azure_ad_b2c?.authority ?? '',
    knownAuthorities: config?.azure_ad_b2c?.knownAuthorities ?? [],
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
});

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

export const clearSession = () => {
  const notifications = localStorage.getItem('notifications');
  clearCookies();
  sessionStorage.clear();
  localStorage.clear();
  if (notifications) {
    localStorage.setItem('notifications', notifications);
  }
};
