import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';

import TenantSelector from 'containers/auth';
import AuthPage from 'containers/auth/components/AuthPage';
import { useAppSelector, useAppDispatch } from 'store/index';
import {
  selectedTenant,
  setAuthenticated,
  setToken,
  setTokenParsed,
  setSessionId,
} from 'store/auth';
import App from './App';

export default function AuthenticationChecker() {
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();

  const [isConnecting, setIsConnecting] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const account = useAccount(accounts[0] || {});
  const tenant = useAppSelector(selectedTenant);

  useEffect(() => {
    const authInProgress = sessionStorage.getItem('authInProgress') === 'true';
    setIsSigningIn(authInProgress);
    if (authInProgress) {
      setIsConnecting(false);
    }
  }, []);

  // Handle successful login and token extraction
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          account: account,
          scopes: ['openid'],
        })
        .then((response) => {
          const idToken = response.idToken;
          const idTokenClaims = response.idTokenClaims;

          if (idToken && idTokenClaims) {
            setIsAuthenticated(true);
            setIsConnecting(false);
            sessionStorage.removeItem('authInProgress');
            setIsSigningIn(false);
            dispatch(setAuthenticated(true));
            dispatch(setToken(idToken));
            dispatch(setTokenParsed(idTokenClaims));
            dispatch(setSessionId(response.correlationId));
          }
        })
        .catch((error) => {
          setIsConnecting(false);
          setIsSigningIn(false);
          sessionStorage.removeItem('authInProgress');
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              scopes: ['openid'],
            });
          } else {
            console.error(error);
          }
        });
    }
  }, [account, instance, dispatch]);

  const handleSignIn = () => {
    setIsConnecting(true);
    sessionStorage.setItem('authInProgress', 'true');
    instance.loginRedirect({
      scopes: ['openid'],
    });
  };

  // Show the AuthPage if the user is not authenticated and we're either connecting or signing in
  if (!isAuthenticated) {
    return (
      <AuthPage isConnecting={isConnecting} isSigningIn={isSigningIn} onSignIn={handleSignIn} />
    );
  }

  // Once authenticated, show the TenantSelector if the tenant is not selected
  if (isAuthenticated && (!tenant || isEmpty(tenant?.roles))) {
    return <TenantSelector />;
  }

  // When the tenant is selected, show the main App
  if (isAuthenticated && tenant) {
    return <App />;
  }

  return null;
}
