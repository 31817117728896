import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Tenant, User, UserPreferences } from 'models/user';

import { RootState } from 'store/rootReducer';
import { loadState } from 'utils/localStorage';
import { registryApi } from 'services/registry';
import { Config, configuratorApi } from 'services/configurator';
import { EXTERNAL_IDS } from '@constants/reports';

export interface InitialAccountStateProps {
  user: User;
  tabSelected: number;
  patients?: User[];
  tenant?: Tenant;
  config?: Config;
}

const initialState: InitialAccountStateProps = {
  user: {
    id: '',
    personalInfo: {
      firstName: '',
      lastName: '',
    },
    contactInfo: {},
    languageRegion: {},
    sendbirdInfo: {
      id: null,
      channel: null,
    },
    roles: [],
  },
  tabSelected: 0,
  patients: [],
  tenant: loadState('tenant'),
  config: {
    tenant: {
      chat: 'ON',
      hosts: {},
      reportExternalIds: [],
    },
    app: {
      idleTimerTimeout: 15 * 60 * 1000,
      sendbird_app_id: '',
      tenant_config_path_url: '',
      azure_ad_b2c: {
        clientId: '',
        authority: '',
        knownAuthorities: [],
      },
    },
  },
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<Tenant | undefined>) => {
      return {
        ...state,
        tenant: action.payload
          ? {
              ...action.payload,
            }
          : undefined,
      };
    },
    setTabSelected: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        tabSelected: action.payload,
      };
    },
    setProfilePhoto: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        user: {
          ...state.user,
          profilePhoto: action.payload,
          personalInfo: {
            ...state.user.personalInfo,
            photoUrl: action.payload,
          },
        },
      };
    },
    setSignature: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        user: {
          ...state.user,
          personalInfo: {
            ...state.user.personalInfo,
            signUrl: action.payload,
          },
        },
      };
    },
    updateUserPreferences: (
      state,
      action: PayloadAction<Partial<UserPreferences> | null | undefined>
    ) => {
      if (action.payload == null) {
        state.user.portalSettings = null;
      }
      // do not overwrite the entire settings object because the settings are updated one by one,
      // i.e. when changing the color mode, action.payload will be `{ colorMode: newColorMode }`
      state.user.portalSettings = { ...state.user.portalSettings, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      registryApi.endpoints.fetchUserAccount.matchFulfilled,
      (state, { payload }) => {
        state.user = {
          ...payload,
        };
      }
    );
    builder.addMatcher(
      registryApi.endpoints.fetchPatientsAssignToClinician.matchFulfilled,
      (state, { payload }) => {
        state.patients = [...payload];
      }
    );
    builder.addMatcher(
      configuratorApi.endpoints.getAppConfig.matchFulfilled,
      (state, { payload }) => {
        state.config = {
          ...state.config,
          app: payload.app,
          tenant: {
            ...payload.tenant,
            chat: payload.tenant?.chat || 'ON',
            hosts: payload.tenant?.hosts,
            reportExternalIds: payload.tenant?.reportExternalIds,
          },
        };
      }
    );
  },
});

export const { setTenant, setTabSelected, setProfilePhoto, setSignature, updateUserPreferences } =
  accountSlice.actions;
export const userAccount = (state: RootState) => state.account.user;
export const tabSelected = (state: RootState) => state.account.tabSelected;
export const userIdSelector = (state: RootState) => state.account.user?.id;
export const userTitleSelector = (state: RootState) => state.account.user?.personalInfo?.title;
export const assignedPatientSelector = (state: RootState) => state.account.patients;
export const userTimeZoneSelector = (state: RootState) =>
  state.account.user?.languageRegion?.timezone;
export const userPreferencesSelector = (state: RootState) => state.account.user?.portalSettings;
export const configSelector = (state: RootState) => state.account.config;
export const chatConfigSelector = (state: RootState) => state.account.config?.tenant?.chat;
export const externalIdsSelector = (state: RootState) =>
  state.account.config?.tenant?.reportExternalIds;
export const reportExternalIdsSelector = (state: RootState) =>
  state.account.config?.tenant?.reportExternalIds
    ?.filter((external) => external.useInReports)
    .map((external) => ({ label: external.displayName, key: EXTERNAL_IDS[external.externalId] }));

export default accountSlice.reducer;
