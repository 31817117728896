import { chunk, isArray, keyBy, map, sortBy, uniq } from 'lodash';

import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  EngagementCareMemberRawData,
  EngagementReportRawData,
  PeriodItem,
  ReportResponse,
} from 'models/reports';
import { userReportsApi } from '.';
import { queryParamsReportsByPeriod } from 'utils/reports';
import { reportFromBandData } from 'utils/reportsBandStatus';
import { reportFromBillingCareMemberData } from 'utils/reportsBillingCareMember';
import type { RootState } from 'store/rootReducer';
import { User } from 'models/user';
import fetchMrn from './helpers/fetchMrn';
import fetchEngagementData from './helpers/fetchEngagementData';
import fetchBillingData from './helpers/fetchBillingData';
import { apiBaseQuery } from 'services/base';
import { reportFromEngagementData } from 'utils/reportsEngagement';
import { format, getUnixTime, parseISO } from 'date-fns';
import fetchOnboardedDates from './helpers/fetchOnboardedDates';
import { reportFromComplianceData } from 'utils/reportsCompliance';

interface ReportQueryParams {
  period: PeriodItem;
  clinicianId?: string;
}

export const adminReportsApi = userReportsApi.injectEndpoints({
  endpoints(builder) {
    return {
      // Care Team Time Tracking Report
      fetchCareTeamTimeTrackingReport: builder.query<
        {
          groupedData: BillingCareMemberRawData[];
          ungroupedData: BillingCareMemberRawData[];
          engagementSummaryData: EngagementCareMemberRawData;
          careTeamSummaryData: Partial<BillingCareMemberRawData>[];
        },
        ReportQueryParams
      >({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const isAdmin = (queryApi.getState() as RootState).auth.tenant.roles.includes(
            'tenant_admin'
          );
          let billingPatientIds: string[] = [];

          if (!isAdmin) {
            // fetching clinician patients
            const patientsResponse = await baseQuery({
              url: `/registry/api/v1/users/${queryParams?.clinicianId}/patients`,
              method: 'GET',
            });

            if (patientsResponse.error) {
              return { error: patientsResponse.error };
            }

            billingPatientIds = (patientsResponse.data as User[]).map((patient) => patient.id);
          }

          // fetching billing data
          const billingResponse = await fetchBillingData({
            queryParams: { ...queryParams, patientIds: billingPatientIds },
            baseQuery,
          });
          const billingData = (billingResponse?.data || []) as BillingCareMemberRawData[];

          if (!billingData.length) {
            return {
              data: {
                groupedData: billingData,
                ungroupedData: billingData,
                engagementSummaryData: {} as EngagementCareMemberRawData,
                careTeamSummaryData: [],
              },
            };
          }

          // fetching fhir data to get MRNs
          const patientIdentifiers = uniq(
            billingData.map((report) => `urn:all.health:user.id|${report.mrn}`)
          );
          const mrnData = await fetchMrn({ patientIdentifiers, baseQuery });

          // fetching engagement app
          const engagementResponse = await fetchEngagementData({
            queryParams: { ...queryParams, patientIds: billingPatientIds },
            baseQuery,
          });
          const engagementData = (engagementResponse?.data || []) as EngagementReportRawData[];
          const engagementKeys = keyBy(engagementData, 'uid');

          const clinicianIds = uniq(
            billingData.map((report: BillingCareMemberRawData) => report.careMemberId)
          );
          const clinicianChunkIds = chunk(clinicianIds, 50);
          const clinicianChunksData = await Promise.all(
            clinicianChunkIds.map(async (ids) =>
              apiBaseQuery('/registry/api/v1')(
                {
                  url: ['/users/?userIds=', ids.join(','), `&size=${ids.length}`].join(''),
                  method: 'GET',
                },
                queryApi,
                extraOptions
              )
            )
          );

          if (clinicianChunksData[0].error) {
            return { error: clinicianChunksData[0].error };
          }

          const cliniciansData =
            clinicianChunksData?.flatMap((obj: any) => obj?.data?.content) || [];

          const clinicianKeys = keyBy(cliniciansData, 'id');

          // fetch onboarded dates by patient
          const memberSinceDates = await fetchOnboardedDates({ extraOptions, queryApi });

          const reports = reportFromBillingCareMemberData({
            billingData,
            engagementKeys,
            mrnData,
            period: queryParams.period,
            clinicianKeys,
            memberSinceDates,
          });

          return {
            data: {
              groupedData: reports.billingByPatient,
              ungroupedData: reports.billingData,
              engagementSummaryData: reports.engagementSummaryData,
              careTeamSummaryData: reports.careTeamSummaryData,
            },
          };
        },
        providesTags: ['Reports'],
      }),

      // Population Engagement Report
      fetchEngagementReport: builder.query<EngagementReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const timezonesOffset = new Date().getTimezoneOffset() * 60;
          const startTime = getUnixTime(queryParams?.period?.value?.start) - timezonesOffset;
          const endTime = getUnixTime(queryParams?.period?.value?.end) - timezonesOffset + 1;
          const engagementResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/engagement?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (engagementResponse.error) {
            return { error: engagementResponse.error };
          }

          const engagementData = (engagementResponse?.data ?? []) as EngagementReportRawData[];

          if (!engagementData?.length) {
            return {
              data: [],
            };
          }

          // formatting engagement reports
          const reportsData = reportFromEngagementData({
            rawData: engagementData,
            period: queryParams.period,
          });

          return {
            data: reportsData,
          };
        },
        providesTags: ['Reports'],
      }),
      fetchAdditionalData: builder.query<
        {
          bandActivationsData: BandStatusReportRawData;
          bandData: BandStatusReportRawData[];
          accountsChartData: any;
        },
        ReportQueryParams
      >({
        async queryFn(queryParams, queryApi, __, baseQuery) {
          const isAdmin = (queryApi.getState() as RootState).auth.tenant.roles.includes(
            'tenant_admin'
          );
          const params = queryParamsReportsByPeriod(queryParams.period);
          const reportFrom = format(queryParams?.period.value.start, 'yyyy-MM-dd');
          const reportTo = format(queryParams?.period.value.end, 'yyyy-MM-dd');

          if (!isAdmin) {
            const patientsResponse = await baseQuery({
              url: `/registry/api/v1/users/${queryParams?.clinicianId}/patients`,
              method: 'GET',
            });

            if (patientsResponse.error) {
              return { error: patientsResponse.error };
            }

            const patientIds = (patientsResponse.data as User[])
              .map((patient) => patient.id)
              .join(',');
            params.append('patientIds', patientIds);
          }

          const accountsResponse = await baseQuery({
            url: `/datalake-service/v1/reports/engagement-care-member/daily?reportFrom=${reportFrom}&reportTo=${reportTo}`,
            method: 'GET',
          });
          const accounts = accountsResponse?.data as ReportResponse;
          const accountsData =
            accounts?._embedded?.engagementCareMemberDailyReportList ||
            accounts?._embedded?.engagementCareMemberMonthlyReportList ||
            accounts?._embedded?.engagementCareMemberWeeklyReportList ||
            [];

          const accountsChartData = sortBy(
            accountsData.map((day: any) => ({
              date: format(new Date(parseISO(day?.reportDate)), 'EEE, LLL dd'),
              value: day?.totalUsersSystem || null,
              timestamp: new Date(parseISO(day?.reportDate)).getTime(),
            })),
            'timestamp'
          );

          let bandData: BandStatusReportRawData[] = [];
          let isBandNext = true;
          let pageBandNumber = 0;

          while (isBandNext) {
            if (params.has('pageNumber')) {
              params.delete('pageNumber');
            }
            params.append('pageNumber', `${pageBandNumber}`);
            const bandResponse = await baseQuery({
              url: `/datalake-service/v1/reports/engagement-band/${
                queryParams.period.period
              }?${params.toString()}`,
              method: 'GET',
            });

            if (bandResponse.error) {
              return { error: bandResponse.error };
            }

            const responseData = bandResponse.data as ReportResponse;
            const bandList =
              ((responseData?._embedded?.engagementBandDailyReportList ||
                responseData?._embedded?.engagementBandMonthlyReportList ||
                responseData?._embedded
                  ?.engagementBandWeeklyReportList) as BandStatusReportRawData[]) || [];

            isBandNext = !!responseData?._links?.next;
            bandData = [...bandData, ...bandList];
            pageBandNumber++;
          }

          const bandActivationsData = map(
            bandData?.filter(
              (band) => band?.numberBandsActivated && band?.numberBandsActivated > 0
            ),
            (bandObj) => ({
              ...bandObj,
              bandAge: Math.ceil((bandObj.bandAgeSeconds || 0) / 86400),
            })
          ) as any;

          return {
            data: { bandActivationsData, bandData, accountsChartData },
          };
        },
        providesTags: ['Reports'],
      }),

      // Population Compliance Report
      fetchComplianceReport: builder.query<EngagementReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const timezonesOffset = new Date().getTimezoneOffset() * 60;
          const startTime = getUnixTime(queryParams?.period?.value?.start) - timezonesOffset;
          const endTime = getUnixTime(queryParams?.period?.value?.end) - timezonesOffset + 1;
          const complianceResponse = await baseQuery({
            url: `/ticks-store/api/v1/reports/compliance?timestamp_from=${startTime}&timestamp_to=${endTime}`,
            method: 'GET',
          });

          if (complianceResponse.error) {
            return { error: complianceResponse.error };
          }

          const complianceData = (complianceResponse?.data ?? []) as EngagementReportRawData[];

          // formatting complaince report
          const complianceReportData = reportFromComplianceData({
            rawData: complianceData,
            period: queryParams.period,
          });

          return { data: complianceReportData };
        },
        providesTags: ['Reports'],
      }),

      fetchAdminBandStatusReport: builder.query<BandStatusReportRawData[], ReportQueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          const params = queryParamsReportsByPeriod(queryParams.period);
          let data: BandStatusReportRawData[] = [];
          let isNext = true;
          let pageNumber = 0;

          while (isNext) {
            params.append('pageNumber', `${pageNumber}`);
            const reportsResponse = await baseQuery({
              url: `/datalake-service/v1/reports/engagement-band/${
                queryParams.period.period
              }?${params.toString()}`,
              method: 'GET',
            });

            if (reportsResponse.error) {
              return { error: reportsResponse.error };
            }

            const responseData = reportsResponse.data as ReportResponse;
            const reportList =
              ((responseData?._embedded?.engagementBandDailyReportList ||
                responseData?._embedded?.engagementBandMonthlyReportList ||
                responseData?._embedded
                  ?.engagementBandWeeklyReportList) as BandStatusReportRawData[]) || [];

            isNext = !!responseData?._links?.next;
            data = [...data, ...reportList];
            pageNumber++;
          }

          const reportsData: BandStatusReportRawData[] =
            isArray(data) && data.length > 0 ? reportFromBandData(data) : [];

          return { data: reportsData };
        },
        providesTags: ['AdminReports'],
      }),
    };
  },
});

export const {
  useLazyFetchCareTeamTimeTrackingReportQuery,
  useLazyFetchEngagementReportQuery,
  useLazyFetchAdditionalDataQuery,
  useLazyFetchComplianceReportQuery,
  useLazyFetchAdminBandStatusReportQuery,
} = adminReportsApi;
