import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, CircularProgress, SelectChangeEvent, Typography } from '@mui/material';
import { uniqBy } from 'lodash';

import { LogoAllIcon } from 'assets/icons';
import SelectInput from 'components/Inputs/SelectInput';
import { setTenant, tokenParsed } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/index';
import useStyles from './styles';
import { useUserAnalytics } from 'hooks/useUserAnalytics';
import { userApi } from 'services/user';
import { Tenant, UserRole } from 'models/user';

const TenantSelector = () => {
  const [fetchUserTenant, { data: userTenants, isLoading: isLoadingTenants }] =
    userApi.endpoints.fetchUserTenant.useLazyQuery();
  const { onCreateEvent } = useUserAnalytics();
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);

  const searchParams = new URLSearchParams(window.location.search);
  const magicTenantId = searchParams.get('tenant_id');

  const rolesByTenantName = useMemo(() => {
    const result: Record<string, UserRole[]> = {};
    if (!userTenants) {
      return result;
    }
    for (let tenant of userTenants) {
      if (!result[tenant.tenantName]) {
        result[tenant.tenantName] = [tenant.role];
      } else {
        result[tenant.tenantName].push(tenant.role);
      }
    }
    return result;
  }, [userTenants]);

  const uniqueTenants = useMemo(() => uniqBy(userTenants, (it) => it.tenantUuid), [userTenants]);

  const tenantsByName = useMemo(() => {
    if (!uniqueTenants) {
      return {};
    }
    return Object.fromEntries(uniqueTenants.map((it) => [it.tenantName, it]));
  }, [uniqueTenants]);

  useEffect(() => {
    if (tokenParsedSelector?.extension_AllHealthID && tokenParsedSelector?.iss) {
      fetchUserTenant({
        userId: tokenParsedSelector.extension_AllHealthID,
        type: tokenParsedSelector.iss.includes('patient') ? 'patients' : 'clinicians',
      });
    }
  }, [fetchUserTenant, tokenParsedSelector]);

  useEffect(() => {
    if (magicTenantId && uniqueTenants.length) {
      const magicTenant = uniqueTenants.find((tenant) => tenant.tenantUuid === magicTenantId);
      if (magicTenant) {
        const tenant = {
          tenant: magicTenant.tenantName,
          tenant_ID: magicTenant.tenantUuid,
          role_ID: magicTenant.role,
          roles: rolesByTenantName[magicTenant.tenantName] || [],
        };
        localStorage.setItem('tenant', JSON.stringify(tenant));
        dispatch(setTenant(tenant));
      }
    }
  }, [magicTenantId, uniqueTenants, dispatch, rolesByTenantName]);

  const handleTenant = (event: SelectChangeEvent<string>) => {
    const tenantName: string = event?.target?.value;
    const tenant = {
      tenant: tenantName,
      tenant_ID: tenantsByName[tenantName].tenantUuid,
      role_ID: tenantsByName[tenantName].role,
      roles: rolesByTenantName[tenantName] || [],
    };
    setSelectedTenant(tenant);
  };

  const handleSelect = (event: React.MouseEvent) => {
    if (!selectedTenant) {
      return;
    }
    onCreateEvent(event, '<TenantSelector />', '<Login />');
    localStorage.setItem('tenant', JSON.stringify(selectedTenant));
    dispatch(setTenant(selectedTenant));
  };

  if (isLoadingTenants || magicTenantId) {
    return (
      <Box>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.logoContainer}>
        <LogoAllIcon className={classes.logo} />
        <Typography variant="header19" className={classes.text}>
          Select Tenant
        </Typography>
      </Box>

      <Box className={classes.selector}>
        <SelectInput
          label="Select tenant"
          value={selectedTenant}
          onChange={handleTenant}
          options={uniqueTenants}
        />
      </Box>

      <Button
        onClick={handleSelect}
        variant="cta"
        color="black"
        className={classes.button}
        disabled={!selectedTenant}
        fullWidth
      >
        Select Tenant
      </Button>
    </Box>
  );
};

export default TenantSelector;
