import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/index';
import { currentToken, selectedTenant, tokenParsed } from 'store/auth';
import { AnalyticsEvent } from 'lib/analytics/models/analytics';
import { UserInfo } from 'lib/analytics/models/userInfo';
import { postUserPageUsageEvents } from 'lib/analytics/services/analyticsTracker';
import { generateAnalyticsEvent } from 'lib/analytics/services/eventGenerator';
import { EVENT_NAME } from 'lib/analytics/constants/analyticsEvent';

const LocatorTracker = () => {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState<AnalyticsEvent | undefined>(undefined);
  const tenant = useAppSelector(selectedTenant);
  const token = useAppSelector(currentToken);
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const userInfo: UserInfo = {
    userId: tokenParsedSelector?.extension_AllHealthID || '',
    userToken: token || '',
    tenantId: tenant.tenant_ID || '',
    sessionId: tokenParsedSelector?.correlationId || '', //TODO: add real session id
  };

  const trackPageView = () => {
    const event: AnalyticsEvent = generateAnalyticsEvent(userInfo, location.pathname);

    let trackedEvents: AnalyticsEvent[] = [];

    if (previousPath && previousPath?.ep?.visit !== location.pathname) {
      let viewEndEvent: AnalyticsEvent = {
        ...previousPath,
        en: EVENT_NAME.pageViewEnd,
        et: Math.floor(Date.now() / 1000),
      };

      trackedEvents.push(previousPath);
      trackedEvents.push(viewEndEvent);
    }

    setPreviousPath(event);

    return trackedEvents;
  };

  useEffect(() => {
    const events: AnalyticsEvent[] = trackPageView();

    if (events.length !== 0 && userInfo.tenantId) {
      postUserPageUsageEvents(userInfo, events);
    }
    // eslint-disable-next-line
  }, [location]);

  return null;
};

export default LocatorTracker;
