import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import useStyles from './styles';

const ErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.errorContainer}>
        <Typography variant="header24">
          We're sorry, but the application failed to start due to a configuration error
        </Typography>
        <Typography variant="header15" sx={{ fontWeight: 500 }}>
          Please contact your system administrator to resolve this issue
        </Typography>
      </Box>
    </div>
  );
};

export default ErrorPage;
