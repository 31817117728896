import {
  addDays,
  addMonths,
  eachWeekOfInterval,
  endOfDay,
  endOfWeek,
  format,
  getUnixTime,
  getWeek,
  isBefore,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import {
  floor,
  groupBy,
  isEmpty,
  isNil,
  isNumber,
  map,
  mean,
  merge,
  orderBy,
  padStart,
  round,
  sumBy,
  uniq,
  uniqBy,
} from 'lodash';
import { WEIGHT_CONFIDENCE_THRESHOLD } from '@constants/charts';
import { User } from 'models/user';
import {
  BillingReportPatient,
  ClinicalReportPatientData,
  DateRange,
  Period,
  PeriodItem,
  ReportTick,
} from 'models/reports';
import { UnitSystem } from './unitSystem';
import { formatTimer } from './formatTimer';

export const getClinicalFacts = (facts: string[], startTime: number, endTime: number): string[] => {
  return facts.map((fact: string) => `${fact}_from_${startTime}_to_${endTime}`);
};

export const getTimezoneMonthDate = (dateRange: DateRange, tz: string) => {
  const timezonesOffset = new Date().getTimezoneOffset() * 60 + getTimezoneOffset(tz) / 1000;
  const startTime = getUnixTime(dateRange.start) - timezonesOffset;
  const endTime = getUnixTime(dateRange.end) - timezonesOffset;

  return {
    startTime,
    endTime,
  };
};

export const getTimezoneDate = (end: Date, tz: string) => {
  const timezonesOffset = new Date().getTimezoneOffset() * 60 + getTimezoneOffset(tz) / 1000;
  const startTime = getUnixTime(startOfMonth(addMonths(end, -2))) - timezonesOffset;
  const endTime = getUnixTime(endOfDay(end)) - timezonesOffset;

  return {
    startTime,
    endTime,
  };
};

export const reportFromFactsData = (
  patientData: User,
  tz: string,
  startTime: number,
  endTime: number,
  facts: any,
  monthFacts: any,
  weekFacts: any,
  dailyFacts: any,
  distFacts: any,
  teams?: User[],
  unitSystem?: UnitSystem,
  ticks?: { [key: string]: Array<ReportTick> },
  mrn?: string | null
): ClinicalReportPatientData => {
  return {
    id: patientData.id,
    firstName: patientData.personalInfo.firstName,
    lastName: patientData.personalInfo.lastName,
    mrn,
    tz,
    birthday: patientData.personalInfo.birthday,
    sex: patientData.personalInfo.sex,
    height: patientData.bioInfo?.heightInMeters,
    weight: patientData.bioInfo?.weightInKilograms,
    photoUrl: patientData.personalInfo.photoUrl,
    chartMonthData: getMonthData(monthFacts, tz, unitSystem),
    chartWeekData: getWeekData(weekFacts, tz),
    chartWeeklyData: getWeeklyData(facts, startTime, endTime),
    chartDailyData: getDailyData(dailyFacts, tz, unitSystem),
    chartDistData: getDistData(distFacts),
    activity: getActivity(facts, endTime),
    sleep: getSleep(facts, endTime),
    biometrics: getBiometrics(facts, endTime),
    teams,
    facts: getFacts(facts, startTime, endTime),
    ticks: ticks && !isEmpty(ticks) ? getTicksFormatted({ ticks, tz, unitSystem }) : undefined,
  };
};

const getTicksFormatted = ({
  ticks,
}: {
  ticks: { [key: string]: Array<ReportTick> };
  tz: string;
  unitSystem?: UnitSystem;
}) => {
  const bloodPressure = getBloodPressureData(ticks);
  const weight = ticks?.weight
    ? ticks?.weight
        ?.map((tick) => {
          const confData = ticks?.weight_conf?.find((conf) => conf[0] === tick[0]);
          const conf = confData ? confData[1] : 100;
          return {
            timestamp: tick[0] * 1000,
            weight: tick[1],
            conf,
          };
        })
        ?.filter((tick) => tick.conf >= WEIGHT_CONFIDENCE_THRESHOLD)
    : [];
  return {
    bloodPressure,
    weight,
  };
};

const getBloodPressureData = (rawData: any) => {
  if (!isEmpty(rawData) && rawData?.systolic) {
    const data = rawData;

    const composedData = [...data.systolic, ...data.diastolic];
    const uniqueTimestamps = uniq(map(composedData, 0));

    return uniqueTimestamps.map((time: number) => {
      const hasDiastolic = data.diastolic.find((d: any) => d[0] === time);
      const hasSystolic = data.systolic.find((d: any) => d[0] === time);
      return {
        timestamp: time * 1000,
        systolic_data: hasSystolic ? hasSystolic[1] : null,
        diastolic_data: hasDiastolic ? hasDiastolic[1] : null,
      };
    });
  }

  return [];
};

const getDaysOfWeekData = (facts: any, factName: string, startTime: number, endTime: number) => {
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const weekData = daysOfWeek
    .map((day) => facts[`${factName}_${day}_from_${startTime}_to_${endTime}`])
    .filter((i) => !isNil(i));
  if (weekData.length > 0) {
    if (factName === 'sleep_time_onset_mean') {
      const sleepData = weekData.map((item) => (item >= 12 * 3600 ? item : item + 24 * 3600));
      const meanValue = mean(sleepData);

      return [meanValue];
    } else {
      const meanValue = mean(weekData);
      const minValue = Math.min(...weekData);
      const maxValue = Math.max(...weekData);

      return [meanValue, minValue, maxValue];
    }
  }

  return [];
};

const getFacts = (facts: any, startTime: number, endTime: number) => {
  const [steps_mean_week, steps_min_week, steps_max_week] = getDaysOfWeekData(
    facts,
    'steps_mean',
    startTime,
    endTime
  );
  const [band_wearing_time_mean_week] = getDaysOfWeekData(
    facts,
    'band_wearing_time_mean',
    startTime,
    endTime
  );
  const [band_charge_time_mean_week] = getDaysOfWeekData(
    facts,
    'band_charge_time_mean',
    startTime,
    endTime
  );
  const [band_removed_time_mean_week] = getDaysOfWeekData(
    facts,
    'band_removed_time_mean',
    startTime,
    endTime
  );
  const [sleep_time_onset_mean_week] = getDaysOfWeekData(
    facts,
    'sleep_time_onset_mean',
    startTime,
    endTime
  );
  const [sleep_time_final_awekening_mean_week] = getDaysOfWeekData(
    facts,
    'sleep_time_final_awekening_mean',
    startTime,
    endTime
  );
  const [sleep_timetosleep_mean_week] = getDaysOfWeekData(
    facts,
    'sleep_timetosleep_mean',
    startTime,
    endTime
  );
  const [sleep_efficiency_mean_week, sleep_efficiency_min_week, sleep_efficiency_max_week] =
    getDaysOfWeekData(facts, 'sleep_efficiency_mean', startTime, endTime);

  return {
    ...facts,
    [`steps_min_week_from_${startTime}_to_${endTime}`]: steps_min_week,
    [`steps_max_week_from_${startTime}_to_${endTime}`]: steps_max_week,
    [`steps_mean_week_from_${startTime}_to_${endTime}`]: steps_mean_week,
    [`band_wearing_time_mean_week_from_${startTime}_to_${endTime}`]: band_wearing_time_mean_week,
    [`band_charge_time_mean_week_from_${startTime}_to_${endTime}`]: band_charge_time_mean_week,
    [`band_removed_time_mean_week_from_${startTime}_to_${endTime}`]: band_removed_time_mean_week,
    [`sleep_time_onset_mean_week_from_${startTime}_to_${endTime}`]: sleep_time_onset_mean_week,
    [`sleep_time_final_awekening_mean_week_from_${startTime}_to_${endTime}`]:
      sleep_time_final_awekening_mean_week,
    [`sleep_timetosleep_mean_week_from_${startTime}_to_${endTime}`]: sleep_timetosleep_mean_week,
    [`sleep_efficiency_min_week_from_${startTime}_to_${endTime}`]: sleep_efficiency_min_week,
    [`sleep_efficiency_max_week_from_${startTime}_to_${endTime}`]: sleep_efficiency_max_week,
    [`sleep_efficiency_mean_week_from_${startTime}_to_${endTime}`]: sleep_efficiency_mean_week,
  };
};

const getMonthData = (monthFacts: any[], tz: string, unitSystem: any) => {
  const mappedFacts = monthFacts
    .sort((a, b) => a.timestamp_from - b.timestamp_from)
    .map((item: any) => {
      const timestamp = (item.timestamp || item.timestamp_from) * 1000;
      const isSleepItem = Object.keys(item.data).join('').includes('sleep');
      const { rem, light, deep, awake } = getSleepStages(
        item.data?.sleep_rem || null,
        item.data?.sleep_light || null,
        item.data?.sleep_deep || null,
        item.data?.sleep_awake || null
      );

      return {
        ...item.data,
        ...(isSleepItem
          ? {
              sleep_time_to_sleep: item.data?.sleep_time_to_sleep / 60 || null,
              sleep_duration_mean: item.data?.sleep_duration / 3600 || null,
              sleep_rem_mean: rem,
              sleep_light_mean: light,
              sleep_deep_mean: deep,
              sleep_awake_mean: awake,
              sleep_bed_time:
                item.data?.sleep_time_onset < 3600 * 12
                  ? -item.data?.sleep_time_onset
                  : 24 * 3600 - item.data?.sleep_time_onset,
              sleep_wake_time:
                item.data?.sleep_time_final_awekening < 3600 * 12
                  ? -item.data?.sleep_time_final_awekening
                  : item.data?.sleep_time_final_awekening,
            }
          : {}),
        ...(item.data?.idle_time_mean && {
          idle_time_mean: item.data?.idle_time_mean / 3600 || null,
        }),
        ...(item.data?.spo2_mean && {
          spo2_mean: item.data?.spo2_mean
            ? formatValue('spo2_mean', item.data?.spo2_mean, unitSystem)
            : null,
          spo2_min: item.data?.spo2_min
            ? formatValue('spo2_min', item.data?.spo2_min, unitSystem)
            : null,
          spo2_max: item.data?.spo2_max
            ? formatValue('spo2_max', item.data?.spo2_max, unitSystem)
            : null,
        }),
        ...(item.data?.weight_mean && {
          weight_mean: item.data?.weight_mean
            ? formatValue('weight_mean', item.data?.weight_mean, unitSystem)
            : null,
          weight_min: item.data?.weight_min
            ? formatValue('weight_min', item.data?.weight_min, unitSystem)
            : null,
          weight_max: item.data?.weight_max
            ? formatValue('weight_max', item.data?.weight_max, unitSystem)
            : null,
        }),
        ...(item.data?.heart_rate_mean && {
          heart_rate_mean: item.data?.heart_rate_mean
            ? formatValue('heart_rate_mean', item.data?.heart_rate_mean, unitSystem)
            : null,
          heart_rate_min: item.data?.heart_rate_min
            ? formatValue('heart_rate_min', item.data?.heart_rate_min, unitSystem)
            : null,
          heart_rate_max: item.data?.heart_rate_max
            ? formatValue('heart_rate_max', item.data?.heart_rate_max, unitSystem)
            : null,
        }),
        ...(item.data?.resp_rate_mean && {
          resp_rate_mean: item.data?.resp_rate_mean
            ? formatValue('resp_rate_mean', item.data?.resp_rate_mean, unitSystem)
            : null,
          resp_rate_min: item.data?.resp_rate_min
            ? formatValue('resp_rate_min', item.data?.resp_rate_min, unitSystem)
            : null,
          resp_rate_max: item.data?.resp_rate_max
            ? formatValue('resp_rate_max', item.data?.resp_rate_max, unitSystem)
            : null,
        }),
        bmi: item.data?.bmi ? formatValue('bmi', item.data?.bmi, unitSystem) : null,
        sleep_efficiency_mean: item.data?.sleep_efficiency_mean
          ? formatValue('sleep_efficiency_mean', item.data?.sleep_efficiency_mean, unitSystem)
          : null,
        band_removed_time: item.data?.band_removed_time_mean,
        band_charge_time: item.data?.band_charge_time_mean,
        band_wearing_time: item.data?.band_wearing_time_mean,
        date: formatInTimeZone(timestamp, tz, 'MMMM'),
        tooltipDate: formatInTimeZone(timestamp, tz, 'MMMM, yyyy'),
        tableDate: formatInTimeZone(timestamp, tz, 'MMMM, yyyy'),
        csvDate: formatInTimeZone(timestamp, tz, 'yyyy-MM-dd'),
        timestamp,
      };
    });

  return Object.values(groupBy(mappedFacts, 'date')).map((items) => {
    return merge({}, ...items);
  });
};

const getWeekData = (weekFacts: any[], tz: string) => {
  return uniqBy(
    weekFacts
      .sort((a, b) => b.timestamp_from - a.timestamp_from)
      .map((item: any) => {
        const timestampFrom = (item.timestamp_from + 3600) * 1000;
        const timestampTo = (item.timestamp_to - 3600) * 1000;
        return {
          active_time_sum: item.data?.active_time_sum / 60 || 0,
          date: `${formatInTimeZone(timestampFrom, tz, 'MMM d')} - ${formatInTimeZone(
            timestampTo,
            tz,
            'MMM d'
          )}`,
        };
      }),
    'date'
  );
};

const getWeeklyData = (facts: any, startTime: number, endTime: number) => {
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  return {
    steps: daysOfWeek.map((day) => ({
      date: day,
      steps_mean: facts[`steps_mean_${day}_from_${startTime}_to_${endTime}`] || null,
    })),
    sleep_duration: daysOfWeek.map((day) => ({
      date: day,
      sleep_duration: facts[`sleep_duration_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_deep: facts[`sleep_deep_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_rem: facts[`sleep_rem_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_light: facts[`sleep_light_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_awake: facts[`sleep_awake_mean_${day}_from_${startTime}_to_${endTime}`] || null,
    })),
    sleep_consistency: daysOfWeek.map((day) => ({
      date: day,
      sleep_time_to_sleep: facts[`sleep_timetosleep_mean_${day}_from_${startTime}_to_${endTime}`]
        ? facts[`sleep_timetosleep_mean_${day}_from_${startTime}_to_${endTime}`] / 60
        : null,
      sleep_time_onset:
        facts[`sleep_time_onset_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_time_final_awekening:
        facts[`sleep_time_final_awekening_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      sleep_bed_time: facts[`sleep_time_onset_mean_${day}_from_${startTime}_to_${endTime}`]
        ? facts[`sleep_time_onset_mean_${day}_from_${startTime}_to_${endTime}`] < 3600 * 12
          ? -facts[`sleep_time_onset_mean_${day}_from_${startTime}_to_${endTime}`]
          : 24 * 3600 - facts[`sleep_time_onset_mean_${day}_from_${startTime}_to_${endTime}`]
        : null,
      sleep_wake_time: facts[
        `sleep_time_final_awekening_mean_${day}_from_${startTime}_to_${endTime}`
      ]
        ? facts[`sleep_time_final_awekening_mean_${day}_from_${startTime}_to_${endTime}`] <
          3600 * 12
          ? -facts[`sleep_time_final_awekening_mean_${day}_from_${startTime}_to_${endTime}`]
          : facts[`sleep_time_final_awekening_mean_${day}_from_${startTime}_to_${endTime}`]
        : null,
      sleep_num_intra_sleep_awakenings:
        facts[`sleep_num_intra_sleep_awakenings_mean_${day}_from_${startTime}_to_${endTime}`] ||
        null,
    })),
    sleep_efficiency: daysOfWeek.map((day) => ({
      date: day,
      sleep_efficiency_mean:
        facts[`sleep_efficiency_mean_${day}_from_${startTime}_to_${endTime}`] || null,
    })),
    band_removed: daysOfWeek.map((day) => ({
      date: day,
      band_removed_time:
        facts[`band_removed_time_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      band_charge_time:
        facts[`band_charge_time_mean_${day}_from_${startTime}_to_${endTime}`] || null,
      band_wearing_time:
        facts[`band_wearing_time_mean_${day}_from_${startTime}_to_${endTime}`] || null,
    })),
  };
};

const getDailyData = (monthFacts: any[], tz: string, unitSystem: any) => {
  const mappedFacts = monthFacts
    .sort((a, b) => a.timestamp_from - b.timestamp_from)
    .map((item: any) => {
      const timestamp = (item.timestamp || item.timestamp_from) * 1000;
      const isSleepItem = Object.keys(item.data).join('').includes('sleep');
      const { rem, light, deep, awake } = getSleepStages(
        item.data?.sleep_rem || null,
        item.data?.sleep_light || null,
        item.data?.sleep_deep || null,
        item.data?.sleep_awake || null
      );

      return {
        ...item.data,
        ...(isSleepItem
          ? {
              sleep_time_to_sleep: item.data?.sleep_time_to_sleep / 60 || null,
              sleep_duration_mean: item.data?.sleep_duration / 3600 || null,
              sleep_rem_mean: rem,
              sleep_light_mean: light,
              sleep_deep_mean: deep,
              sleep_awake_mean: awake,
              sleep_bed_time:
                item.data?.sleep_time_onset < 3600 * 12
                  ? -item.data?.sleep_time_onset
                  : 24 * 3600 - item.data?.sleep_time_onset,
              sleep_wake_time:
                item.data?.sleep_time_final_awekening < 3600 * 12
                  ? -item.data?.sleep_time_final_awekening
                  : item.data?.sleep_time_final_awekening,
            }
          : {}),
        ...(item.data?.idle_time_mean && {
          idle_time_mean: item.data?.idle_time_mean / 3600 || null,
        }),
        ...(item.data?.spo2_mean && {
          spo2_mean: item.data?.spo2_mean
            ? formatValue('spo2_mean', item.data?.spo2_mean, unitSystem)
            : null,
          spo2_min: item.data?.spo2_min
            ? formatValue('spo2_min', item.data?.spo2_min, unitSystem)
            : null,
          spo2_max: item.data?.spo2_max
            ? formatValue('spo2_max', item.data?.spo2_max, unitSystem)
            : null,
        }),
        ...(item.data?.weight_mean && {
          weight_mean: item.data?.weight_mean
            ? formatValue('weight_mean', item.data?.weight_mean, unitSystem)
            : null,
          weight_min: item.data?.weight_min
            ? formatValue('weight_min', item.data?.weight_min, unitSystem)
            : null,
          weight_max: item.data?.weight_max
            ? formatValue('weight_max', item.data?.weight_max, unitSystem)
            : null,
        }),
        ...(item.data?.heart_rate_mean && {
          heart_rate_mean: item.data?.heart_rate_mean
            ? formatValue('heart_rate_mean', item.data?.heart_rate_mean, unitSystem)
            : null,
          heart_rate_min: item.data?.heart_rate_min
            ? formatValue('heart_rate_min', item.data?.heart_rate_min, unitSystem)
            : null,
          heart_rate_max: item.data?.heart_rate_max
            ? formatValue('heart_rate_max', item.data?.heart_rate_max, unitSystem)
            : null,
        }),
        ...(item.data?.resp_rate_mean && {
          resp_rate_mean: item.data?.resp_rate_mean
            ? formatValue('resp_rate_mean', item.data?.resp_rate_mean, unitSystem)
            : null,
          resp_rate_min: item.data?.resp_rate_min
            ? formatValue('resp_rate_min', item.data?.resp_rate_min, unitSystem)
            : null,
          resp_rate_max: item.data?.resp_rate_max
            ? formatValue('resp_rate_max', item.data?.resp_rate_max, unitSystem)
            : null,
        }),
        bmi: item.data?.bmi ? formatValue('bmi', item.data?.bmi, unitSystem) : null,
        sleep_efficiency_mean: item.data?.sleep_efficiency_mean
          ? formatValue('sleep_efficiency_mean', item.data?.sleep_efficiency_mean, unitSystem)
          : null,
        band_removed_time: item.data?.band_removed_time_mean,
        band_charge_time: item.data?.band_charge_time_mean,
        band_wearing_time: item.data?.band_wearing_time_mean,
        date: formatInTimeZone(timestamp, tz, 'MMM d'),
        tooltipDate: formatInTimeZone(timestamp, tz, 'MMMM d, yyyy'),
        tableDate: formatInTimeZone(timestamp, tz, 'EEE MMM dd, yyyy'),
        csvDate: formatInTimeZone(timestamp, tz, 'yyyy-MM-dd'),
        timestamp,
      };
    });

  return Object.values(groupBy(mappedFacts, 'date')).map((items) => {
    return merge({}, ...items);
  });
};

const getActivity = (facts: any, endTime: number) => ({
  steps: {
    value30d: getLocaleString(facts[`steps_mean_30d_at_${endTime}`]) || null,
    value3m: getLocaleString(facts[`steps_mean_3m_at_${endTime}`]) || null,
    daysOfWeek: [
      {
        date: 'Monday',
        data: facts[`steps_mean_mon_30d_at_${endTime}`] || null,
      },
      {
        date: 'Tuesday',
        data: facts[`steps_mean_tue_30d_at_${endTime}`] || null,
      },
      {
        date: 'Wednesday',
        data: facts[`steps_mean_wed_30d_at_${endTime}`] || null,
      },
      {
        date: 'Thursday',
        data: facts[`steps_mean_thu_30d_at_${endTime}`] || null,
      },
      {
        date: 'Friday',
        data: facts[`steps_mean_fri_30d_at_${endTime}`] || null,
      },
      {
        date: 'Saturday',
        data: facts[`steps_mean_sat_30d_at_${endTime}`] || null,
      },
      {
        date: 'Sunday',
        data: facts[`steps_mean_sun_30d_at_${endTime}`] || null,
      },
    ],
  },
  calories: {
    value30d: getLocaleString(facts[`calories_mean_30d_at_${endTime}`]) || null,
    value3m: getLocaleString(facts[`calories_mean_3m_at_${endTime}`]) || null,
  },
  idleTime: {
    value30d: formatTime(facts[`longest_idle_30d_at_${endTime}`]) || null,
    value3m: formatTime(facts[`longest_idle_3m_at_${endTime}`]) || null,
  },
  active: {
    value30d: formatTime(facts[`active_time_sum_30d_at_${endTime}`]) || null,
    value3m: formatTime(facts[`active_time_mean_3m_at_${endTime}`]) || null,
  },
});

const getSleep = (facts: any, endTime: number) => {
  const factScore = facts[`sleep_efficiency_mean_30d_at_${endTime}`];
  return {
    bedtime: {
      value30d: formatDuration(facts[`sleep_time_onset_30d_at_${endTime}`]) || null,
      value3m: formatDuration(facts[`sleep_time_onset_3m_at_${endTime}`]) || null,
    },
    wakeup: {
      value30d: formatDuration(facts[`sleep_time_final_awekening_30d_at_${endTime}`]) || null,
      value3m: formatDuration(facts[`sleep_time_final_awekening_3m_at_${endTime}`]) || null,
    },
    timeToSleep: {
      value30d: formatTime(facts[`sleep_time_to_sleep_mean_30d_at_${endTime}`]) || null,
      value3m: formatTime(facts[`sleep_time_to_sleep_mean_3m_at_${endTime}`]) || null,
    },
    duration: {
      value30d: formatTime(facts[`sleep_duration_mean_30d_at_${endTime}`]) || null,
      value3m: formatTime(facts[`sleep_duration_mean_3m_at_${endTime}`]) || null,
      daysOfWeek: [
        {
          date: 'Monday',
          data: facts[`sleep_duration_mean_mon_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Tuesday',
          data: facts[`sleep_duration_mean_tue_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Wednesday',
          data: facts[`sleep_duration_mean_wed_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Thursday',
          data: facts[`sleep_duration_mean_thu_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Friday',
          data: facts[`sleep_duration_mean_fri_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Saturday',
          data: facts[`sleep_duration_mean_sat_30d_at_${endTime}`] / 3600 || null,
        },
        {
          date: 'Sunday',
          data: facts[`sleep_duration_mean_sun_30d_at_${endTime}`] / 3600 || null,
        },
      ],
    },
    score: factScore ? `${round(factScore)}%` : null,
    awakenings: round(facts[`sleep_awakenings_mean_30d_at_${endTime}`], 1) || null,
    stages: getFactsSleepStages(facts, endTime),
  };
};

const getBiometrics = (facts: any, endTime: number) => ({
  heartRate: getAvgMaxMinData(facts, endTime, 'heart_rate'),
  hrv: getAvgMaxMinData(facts, endTime, 'rmssd'),
  respRate: getAvgMaxMinData(facts, endTime, 'resp_rate'),
  spo2: getAvgMaxMinData(facts, endTime, 'spo2'),
});

const getAvgMaxMinData = (facts: any, endTime: number, metric: string) => {
  const avg = facts[`${metric}_mean_30d_at_${endTime}`] || null;
  const max = facts[`${metric}_max_30d_at_${endTime}`] || null;
  const min = facts[`${metric}_min_30d_at_${endTime}`] || null;

  return metric === 'spo2'
    ? `avg:${round(avg * 100)} max:${round(max * 100)} min:${round(min * 100)}`
    : `avg:${round(avg)} max:${round(max)} min:${round(min)}`;
};

const getDistData = (distFacts: any) => {
  const data = distFacts?.data?.heart_rate_hist || [];
  const heartRateCount = sumBy(data, 'c');
  const resultMap: Record<string, number> = {};
  data?.forEach((item: any) => {
    const range = Math.floor(item.b / 10) * 10;
    if (resultMap[range] !== undefined) {
      resultMap[range] += item.c;
    } else {
      resultMap[range] = item.c;
    }
  });

  const distData = Object.keys(resultMap)?.map((range) => {
    const rawPct =
      heartRateCount && resultMap[range] ? (resultMap[range] / heartRateCount) * 100 : null;
    const percentage = rawPct ? (rawPct > 0.5 ? round(rawPct) : round(rawPct, 1)) : null;
    return {
      range,
      count: resultMap[range],
      percentage,
      rawPct,
      tooltipRange: `${range} - ${Number(range) + 9} bpm`,
    };
  });
  return distData;
};

export const formatDuration = (duration: number) => {
  if (duration) {
    const hours = Math.trunc(duration / 3600);
    const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    const minutes = `${Math.trunc((duration % 3600) / 60)}`.padStart(2, '0');
    const interval = hours > 11 ? 'pm' : 'am';

    return `${formattedHours}:${minutes} ${interval}`;
  }

  return '';
};

export const formatSeconds = (seconds: number) => {
  const days = Math.floor(seconds / 86400);
  const hrs = Math.floor((seconds % 86400) / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedDays = days > 0 ? `${days}d ` : '';
  const formattedHrs = padStart(hrs.toString(), 2, '0');
  const formattedMins = padStart(mins.toString(), 2, '0');
  const formattedSecs = padStart(secs.toString(), 2, '0');

  return `${formattedDays}${formattedHrs}:${formattedMins}:${formattedSecs}`;
};

export const formatSleepChartDuration = (duration: number) => {
  if (!isNil(duration) && isNumber(duration)) {
    if (duration < 0) {
      const hours = Math.trunc(-duration / 3600);
      const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
      const interval = hours > 11 ? 'pm' : 'am';

      return `${formattedHours} ${interval}`;
    } else if (duration === 0) {
      return '12 am';
    } else {
      const hours = Math.trunc((24 * 3600 - duration) / 3600);
      const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
      const interval = hours > 11 ? 'pm' : 'am';

      return `${formattedHours} ${interval}`;
    }
  }

  return '';
};

const formatTime = (seconds: number) => {
  if (seconds) {
    const hours = Math.trunc(seconds / 3600);
    const minutes = Math.trunc((seconds % 3600) / 60);

    return hours ? (minutes ? `${hours} h ${minutes} m` : `${hours} h`) : `${minutes} min`;
  }

  return '';
};

const getFactsSleepStages = (facts: any, endTime: number) => {
  const rem = facts[`sleep_rem_mean_30d_at_${endTime}`] || null;
  const light = facts[`sleep_light_mean_30d_at_${endTime}`] || null;
  const deep = facts[`sleep_deep_mean_30d_at_${endTime}`] || null;
  const awake = facts[`sleep_awake_mean_30d_at_${endTime}`] || null;

  return getSleepStages(rem, light, deep, awake);
};

const getSleepStages = (rem: number, light: number, deep: number, awake: number) => {
  const total = rem + light + deep + awake;

  return {
    rem: (rem / total) * 100 || null,
    light: (light / total) * 100 || null,
    deep: (deep / total) * 100 || null,
    awake: (awake / total) * 100 || null,
  };
};

const getLocaleString = (value: number) => {
  if (value || value === 0) {
    return round(value).toLocaleString('en-US');
  }

  return '';
};

export const getMonthsUntilDate = (max: Date) => {
  let currentMonth = 0;
  const year = max.getFullYear();
  const maxMonth = max.getMonth();
  let months: PeriodItem[] = [];

  while (currentMonth < maxMonth + 1) {
    const start = startOfDay(new Date(year, currentMonth, 1));
    months.push({
      period: 'monthly',
      order: 0,
      label: format(start, 'MMMM yyyy'),
      value: {
        end: endOfDay(lastDayOfMonth(start)),
        start,
      },
      periodNumber: currentMonth + 1,
      year,
    });
    currentMonth++;
  }

  return months;
};

export const getQuartersUntilDate = (endDate: Date) => {
  const endYear = endDate.getFullYear();
  const startDate = new Date(endYear, 0, 1);
  const quarters: PeriodItem[] = [];

  while (startDate <= endDate) {
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const quarter = Math.floor((month + 3) / 3);
    const start = new Date(year, month, 1);
    const end = lastDayOfMonth(new Date(year, month + 2, 1));
    const mid = lastDayOfMonth(new Date(year, month + 1, 1));
    quarters.push({
      period: 'quarterly',
      order: 1,
      label: `Q${quarter}: ${format(start, 'MMM')}, ${format(mid, 'MMM')},  ${format(
        end,
        'MMM yyyy'
      )}`,
      value: {
        end,
        start,
      },
      periodNumber: quarter,
      year,
    });
    startDate.setMonth(month + 3);
  }

  return quarters;
};

export const getWeeksUntilDate = (endDate: Date) => {
  const startYear = startOfYear(endDate);
  const endYear = new Date(endDate.getFullYear(), 11, 31);

  const weeks = eachWeekOfInterval({ start: startYear, end: endYear }, { weekStartsOn: 1 });

  return weeks
    .filter((weekStart) => isBefore(weekStart, endDate))
    .map((weekStart) => {
      const weekEnd = addDays(endOfWeek(weekStart), 1);
      const weekNumber = getWeek(weekStart);
      return {
        period: 'weekly',
        label: `${format(weekStart, 'MM/dd')} - ${format(weekEnd, 'MM/dd yyyy')}`,
        value: { start: weekStart, end: weekEnd },
        periodNumber: weekNumber,
        order: 2,
        year: startYear.getFullYear(),
      } as PeriodItem;
    });
};

export const getPeriodList = (periods: Period[], endDate: Date) => {
  const periodList = periods.flatMap((period) => {
    switch (period) {
      case 'monthly':
        return getMonthsUntilDate(endDate);
      case 'quarterly':
        return getQuartersUntilDate(endDate);
      case 'weekly':
        return getWeeksUntilDate(endDate);
      default:
        return [];
    }
  });

  const ordered = orderBy(periodList, ['value.end', 'order'], ['desc', 'asc']);
  return ordered;
};

export function formatValue(
  filter: string,
  value: number,
  unitSystem: UnitSystem,
  convert: boolean = true
) {
  switch (filter) {
    case 'sleep_duration':
    case 'sleep_light':
    case 'sleep_rem':
    case 'sleep_deep':
    case 'sleep_awake':
    case 'band_wearing_time':
    case 'band_charge_time':
    case 'band_removed_time':
      return formatTimer(value);
    case 'active_time':
    case 'longest_idle':
    case 'sleep_time_to_sleep':
    case 'sleep_timetosleep_mean_week':
    case 'sleep_duration_min':
    case 'sleep_duration_max':
    case 'sleep_duration_mean':
    case 'sleep_rem_mean':
    case 'sleep_deep_mean':
    case 'sleep_light_mean':
    case 'sleep_awake_mean':
    case 'band_wearing_time_mean':
    case 'band_charge_time_mean':
    case 'band_removed_time_mean':
    case 'band_wearing_time_mean_week':
    case 'band_charge_time_mean_week':
    case 'band_removed_time_mean_week':
      return formatTime(value);
    case 'sleep_time_onset':
    case 'sleep_time_onset_min':
    case 'sleep_time_onset_max':
    case 'sleep_time_final_awekening':
    case 'sleep_time_final_awekening_min':
    case 'sleep_time_final_awekening_max':
    case 'sleep_time_onset_mean_week':
    case 'sleep_time_final_awekening_mean_week':
      return value === 0 ? '12:00 am' : formatDuration(value);
    case 'weight_min':
    case 'weight_max':
    case 'weight_mean':
    case 'weight':
      return round(convert ? unitSystem.localeWeight(value) : value);
    case 'skin_temp':
    case 'bmi':
    case 'bmi_min':
    case 'bmi_max':
    case 'bmi_mean':
    case 'sleep_awakenings_mean':
      return round(value, 1);
    case 'spo2_min':
    case 'spo2_max':
    case 'spo2_mean':
      return round(convert ? value * 100 : value);
    case 'sleep_efficiency_min':
    case 'sleep_efficiency_max':
    case 'sleep_efficiency_mean':
    case 'sleep_efficiency_min_week':
    case 'sleep_efficiency_max_week':
    case 'sleep_efficiency_mean_week':
    case 'heart_rate_min':
    case 'heart_rate_max':
    case 'heart_rate_mean':
    case 'resp_rate_min':
    case 'resp_rate_max':
    case 'resp_rate_mean':
    case 'glucose_min':
    case 'glucose_max':
    case 'glucose_mean':
    case 'battery_level':
    case 'systolic_min':
    case 'systolic_max':
    case 'systolic_mean':
    case 'systolic_data':
    case 'diastolic_min':
    case 'diastolic_max':
    case 'diastolic_mean':
    case 'diastolic_data':
    case 'sleep_num_intra_sleep_awakenings':
      return round(value);
    case 'steps_min':
    case 'steps_max':
    case 'steps_mean':
    case 'steps_sum':
    case 'steps_min_week':
    case 'steps_max_week':
    case 'steps_mean_week':
      return getLocaleString(value);
    default:
      return value;
  }
}

export const getUnits = (filter: string, unitSystem: UnitSystem) => {
  switch (filter) {
    case 'weight':
    case 'weight_min':
    case 'weight_max':
    case 'weight_mean':
      return unitSystem.weightSymbol;
    case 'skin_temp':
      return unitSystem.temperatureSymbol;
    case 'spo2':
    case 'spo2_min':
    case 'spo2_max':
    case 'spo2_mean':
    case 'sleep_efficiency':
    case 'sleep_efficiency_min':
    case 'sleep_efficiency_max':
    case 'sleep_efficiency_mean':
    case 'sleep_efficiency_min_week':
    case 'sleep_efficiency_max_week':
    case 'sleep_efficiency_mean_week':
    case 'battery_level':
      return '%';
    case 'heart_rate':
    case 'heart_rate_min':
    case 'heart_rate_max':
    case 'heart_rate_mean':
      return 'bpm';
    case 'resp_rate':
    case 'resp_rate_min':
    case 'resp_rate_max':
    case 'resp_rate_mean':
      return 'br/min';
    case 'sleep_awakenings_mean':
    case 'sleep_num_intra_sleep_awakenings':
      return 'x';
    case 'glucose':
    case 'glucose_min':
    case 'glucose_max':
    case 'glucose_mean':
      return 'mg/dL';
    case 'systolic_min':
    case 'systolic_max':
    case 'systolic_mean':
    case 'systolic_data':
    case 'diastolic_min':
    case 'diastolic_max':
    case 'diastolic_mean':
    case 'diastolic_data':
      return 'mmHg';
    default:
      return '';
  }
};

export const getDateFormatByPeriod = (period: Period, startDate: Date, endDate: Date) => {
  switch (period) {
    case 'quarterly':
      return `${format(startDate, 'MMMM')} - ${format(endDate, 'MMMM yyyy')}`;
    case 'monthly':
      return `${format(endDate, 'MMMM yyyy')}`;
    case 'weekly':
      return `${format(startDate, 'MM/dd')} - ${format(endDate, 'MM/dd yyyy')}`;
    default:
      return `${format(startDate, 'MMMM d')} - ${format(endDate, 'MMMM d yyyy')}`;
  }
};

export const getISOWeekNumber = (date: Date) => {
  const year = date.getFullYear();
  const januaryFourth = new Date(year, 0, 4);
  const daysSinceJanuaryFourth =
    floor((date.getTime() - januaryFourth.getTime()) / (24 * 60 * 60 * 1000)) + 1;
  const januaryFourthDay = januaryFourth.getDay();
  const weekNumber = floor((daysSinceJanuaryFourth + januaryFourthDay - 1) / 7) + 1;
  return weekNumber;
};

export const getQuarterNumber = (date: Date) => {
  const month = date.getMonth() + 1;

  if (month < 4) {
    return 1;
  }

  if (month > 3 && month < 7) {
    return 2;
  }

  if (month > 6 && month < 10) {
    return 3;
  }

  return 4;
};

export function reportFromBillingData(rawData: BillingReportPatient[]) {
  return rawData.map((item: BillingReportPatient) => ({
    memberName: `${item.familyName || ''}, ${item.givenName || ''} `,
    mrn: item.uid,
    bandId: item.bandId,
    icmOnboardDate: item.onboardedTimestamp
      ? format(new Date(item.onboardedTimestamp), 'MM/dd/yyyy')
      : null,
    month: item.month,
    year: item.year,
    daysSinceOnboard: Math.ceil(item.secondsEnrolled / 86400) || null,
    totalDaysBandSynced: item.totalDaysBandSynced,
    ctmId: item.clinicianId,
    careMemberTotalMinutesSpent: item.careMemberTotalSecondsSpent
      ? Math.ceil(item.careMemberTotalSecondsSpent / 60)
      : null,
    careMemberTotalSecondsSpent: item.careMemberTotalSecondsSpent,
    careTeamMemberTime: item.careMemberTotalSecondsSpent
      ? formatSeconds(item.careMemberTotalSecondsSpent)
      : null,
    ctmUser: null,
  }));
}

export function queryParamsReportsByPeriod(period: PeriodItem) {
  let params = new URLSearchParams();
  switch (period.period) {
    case 'monthly':
      params.append('reportMonth', `${period.periodNumber}`);
      params.append('reportYear', `${period.value.start.getFullYear()}`);
      return params;
    case 'quarterly':
      params.append('reportQuarter', `${period.periodNumber}`);
      params.append('reportYear', `${period.value.start.getFullYear()}`);
      return params;
    case 'weekly':
      params.append('reportWeek', `${period.periodNumber}`);
      params.append('reportYear', `${period.value.start.getFullYear()}`);
      return params;
    default:
      const reportFrom = format(period.value.start, 'yyyy-MM-dd');
      const reportTo = format(period.value.end, 'yyyy-MM-dd');
      params.append('reportFrom', reportFrom);
      params.append('reportTo', reportTo);
      return params;
  }
}
